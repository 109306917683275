<template>
	<div class="sidenav-container">
		<md-toolbar class="md-primary">
			<md-button class="md-icon-button" @click="showNavigation = true">
				<md-icon class="menu-toggler">menu</md-icon>
			</md-button>
			<span class="md-title"><img class="logo" src="@/assets/img/logo.png" alt="" style="width:50%;"></span>
		</md-toolbar>

		<md-drawer :md-active.sync="showNavigation" md-swipeable>
			<md-toolbar class="md-transparent" md-elevation="0">
				<!-- <span class="md-title">My App name</span> -->
				<img class="logo" src="@/assets/img/logo.png" alt="">
			</md-toolbar>

			<md-list>
				<md-list-item v-scroll-to="'#collection'" @click="showNavigation=!showNavigation">
					<!-- <md-icon></md-icon> -->
					<router-link to="/" exact="" class="md-list-item-text">
						Η συλλογή Α. Οικονομόπουλου
					</router-link>
				</md-list-item>

				<!-- <md-list-item v-scroll-to="'#foundation'" @click="showNavigation=!showNavigation">
					<router-link to="/about" exact="" class="md-list-item-text">
						Το Ίδρυμα Αικ. Λασκαρίδη
					</router-link>
				</md-list-item> -->

				<md-list-item
					@click="showNavigation=!showNavigation">
					<!-- <md-icon></md-icon> -->
					<router-link to="/search" exact="" class="md-list-item-text">
						Αναζήτηση
					</router-link>
				</md-list-item>
				<md-list-item @click="showNavigation=!showNavigation">
					<!-- <md-icon></md-icon> -->
					<router-link to="/contact" exact="" class="md-list-item-text">
						Επικοινωνία
					</router-link>
				</md-list-item>
			</md-list>
		</md-drawer>
	</div>
</template>

<script>
export default {
	name: 'SideNav',
	data: () => ({
		showNavigation: false,
		showSidepanel: false
	})
}
</script>

<style lang="scss" scoped>

.sidenav-container {
	z-index: 99999999;
}

	.logo {
		margin: 8% 0;
		width: 80%;
	}

	.md-list-item {
		z-index: 99999999;
		background-color: #fff;
    // border: 1px solid $grey;
	}

	.md-list-item-text {
		font-size: 15px;
		margin-left: 2%;
		margin-top: 13%;
	}

	.page-container {
		min-height: 300px;
		overflow: hidden;
		position: relative;
		border: 1px solid rgba(#000, .12);
	}

	// Demo purposes only
	.md-drawer {
		width: 255px;
		max-width: calc(100vw - 125px);
	}

	.md-content {
		padding: 16px;
	}

	.menu-toggler {
		color: $orange!important;
	}

	.md-icon {
		font-family: 'Material Icons' !important;
	}
</style>
