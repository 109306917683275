<template>
	<div class="home">
		<transition name="fadeIn" appear>
			<Hero />

		</transition>
		<Collection ref="bibliothecha" />
		<!-- <div class="lead centering" id="collection" ref="collection">
			<h3>Η συλλογή</h3>
			<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. <br> Ut enim ad minim</p>
			<md-button class="more-btn">ΠΕΡΙΣΣΟΤΕΡΑ</md-button>
			<div class="inner">

			</div>
		</div> -->
		<div class="directory centering">
			<div class="directory-txt">
			<h2>Κατάλογος της Συλλογής <br> & θησαυρός της γραμματείας</h2>
			<p>Η βάση δεδομένων που τίθεται στη διάθεση του κοινού με την παρούσα ιστοσελίδα περιλαμβάνει αναλυτικά όλους τους τίτλους των βιβλίων της συλλογής τα οποία μπορούν να αναζητηθούν με οποιοδήποτε στοιχείο της ταυτότητάς τους, όπως το όνομα του συγγραφέα, κάποια λέξη του τίτλου, τον εκδότη, τον χρόνο έκδοσης κλπ.</p>
			<p>Μοναδικό χαρακτηριστικό της βάσης δεδομένων είναι οτι περιλαμβάνει φωτογραφικό υλικό για όλα τα βιβλία, στοιχείο που την καθιστά εργαλείο έρευνας και μελέτης.</p>
			<p> Επιπλέον συνοδεύεται από έναν εγκυκλοπαιδικό θησαυρό που περιέχει πληροφορίες για τους βίους των συγγραφέων και το συγγραφικό τους έργο.</p>
			<p>
				Με το κουμπί αναζήτησης παρακάτω μπορείτε να περιηγηθείτε σε όλο το εύρος και το βάθος της συλλογής, να ενημερωθείτε για λεπτομέρειες των εκδόσεων της γραμματείας και να αναζητήσετε τις πληροφορίες που σας ενδιαφέρουν.</p>
			</div>
			<div class="directory-btn">
				<md-button @click="goToSearch()" class="more-btn">Αναζητηση</md-button>
			</div>
		</div>
	</div>
</template>

<script>
// @ is an alias to /src
import Hero from '@/components/Hero.vue'
import Collection from '@/components/Collection.vue'
export default {
	name: 'home',
	components: {
		Hero,
		Collection
	},
	methods: {
		goToSearch() {
			this.$router.push('search')
		}
	},
	mounted() {
		// console.log(this.$refs)
	}
}
</script>

<style scoped lang="scss">

.fadeIn-enter-active {
	opacity: 1;
	transition: all 1s ease;
}

.fadeIn-enter {
	opacity: 0;
}

.lead {
	width: 100%;
	min-height: 30vw;
	padding-top: 5%;

	>* {
		font-weight: 100;
	}

	h3 {
		font-size: calc(14px + (50 - 14) * ((100vw - 300px) / (1600 - 300)));
		color: #000;
		margin: 4% 0;
	}

	p {
		font-size: calc(14px + (24 - 14) * ((100vw - 300px) / (1600 - 300)));
		align-self: center;
		line-height: 1.6;
		width: 50%;
	}

	.more-btn {
		width: 12%;
		font-size: calc(10px + (17 - 10) * ((100vw - 300px) / (1600 - 300)));
		cursor: pointer;
		height: 46px;
		border: 1px solid $grey
	}

	.inner {
		background-image: url('../assets/img/photo_books_1.png');
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center bottom;
		width: 100%;
		padding: 10% 0;
	}
}

.collection-photos {
	display: flex;
	width: 100%;
	justify-content: space-between;

	.left,.right {
		width: 49%;
		height: 100%;
	}

}

.devider {
	margin-top: 2%;
}
.directory {
	padding: 3% 16%;
	background-image: url('../assets/img/bg_2.jpg') ;
	background-size: cover;
	background-repeat: no-repeat;

	.directory-txt {
		text-align: center;
		margin-bottom: 1rem;

		h2 {
			line-height: 1.6;
			font-size: calc(14px + (50 - 14) * ((100vw - 300px) / (1600 - 300)));
			margin-bottom: 2rem;
		}

		p {
			font-size: calc(14px + (18 - 14) * ((100vw - 300px) / (1600 - 300)));
			padding: 0 8rem;
			line-height: 1.4;
		}
	}

	.more-btn  {
		font-size: calc(10px + (12 - 10) * ((100vw - 300px) / (1600 - 300)));
		max-width: 290px;
		background: #fff;
		border: 1px solid #979797c3;
		min-width: 120px;
		margin: 1rem 0 0.5rem 0;
	}
}

@media(max-width: 720px) {

.directory {
	.directory-txt {
		p {
			padding: 0 0rem;
		}
	}

}
	}
</style>
